import URI from 'urijs'
import { config } from '../assets/config/config.js'

const utils = {}
utils.iso_regions = {
    'BE-VLG': 'Flanders',
    'BE-WAL': 'Wallonia',
    'BE-BRU': 'Brussels',
}

utils.generateValues = (key, selected) => {
    return config
        .find((item) => item.name === key)
        .values.map((item) => ({
            value: item,
            selected: item === selected,
        }))
}

utils.percentChange = (oldNumber, newNumber) => {
    var decreaseValue = oldNumber - newNumber
    return Math.abs((decreaseValue / oldNumber) * 100)
}

utils.all_langs = (conf) => {
    if (conf.ALL_LANGS && conf.ALL_LANGS.length) {
        return conf.ALL_LANGS
    }
    return ['nl-BE', 'fr-BE', 'en-BE']
}

utils.formatPrice = (x) => {
    return utils.exact_value_formatter(x)
}

utils.clamp = (num, min, max) => Math.min(Math.max(num, min), max)

utils.convertRange = (value, r1, r2) => {
    return ((value - r1[0]) * (r2[1] - r2[0])) / (r1[1] - r1[0]) + r2[0]
}

utils.capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

utils.user_name = (user) => {
    let name = user
    if (typeof name === 'string') {
        if (name.includes('@')) {
            // Extract name from email
            return name
                .split('@')[0]
                .split('.')
                .map(utils.capitalizeFirstLetter)
                .join(' ')
        } else {
            return name
        }
    } else {
        return name
    }
}

utils.computeLabelFromScore = (score, region) => {
    const epcs = utils.epc_mapping[region]

    let currentEpcLabel

    for (let i = Object.keys(epcs).length - 1; i >= 0; i--) {
        currentEpcLabel = Object.keys(epcs)[i]
        if (score > epcs[currentEpcLabel]) break
    }

    const color = utils.epc_color(region, currentEpcLabel)

    return {
        label: currentEpcLabel,
        color,
    }
}

utils.epc_color = (region, label) => {
    if (label === '?') return '#798DA6'

    let colors = {
        Wallonia: {
            'A++': '#00bd00',
            'A+': '#00bd00',
            A: '#00bd00',
            B: '#9ade27',
            C: '#d8db1a',
            D: '#fdf300',
            E: '#fdd400',
            F: '#fa9101',
            G: '#d62015',
        },
        'BE-WAL': {
            'A++': '#00bd00',
            'A+': '#00bd00',
            A: '#00bd00',
            B: '#9ade27',
            C: '#d8db1a',
            D: '#fdf300',
            E: '#fdd400',
            F: '#fa9101',
            G: '#d62015',
        },
        Flanders: {
            'A+': '#00bd00',
            A: '#9ade27',
            B: '#d8db1a',
            C: '#fdf300',
            D: '#fdd400',
            E: '#fa9101',
            F: '#d62015',
        },
        'BE-VLG': {
            'A+': '#00bd00',
            A: '#9ade27',
            B: '#d8db1a',
            C: '#fdf300',
            D: '#fdd400',
            E: '#fa9101',
            F: '#d62015',
        },
        Brussels: {
            A: '#00bd00',
            B: '#9ade27',
            C: '#d8db1a',
            D: '#fdf300',
            E: '#fdd400',
            F: '#fa9101',
            G: '#d62015',
        },
        'BE-BRU': {
            A: '#00bd00',
            B: '#9ade27',
            C: '#d8db1a',
            D: '#fdf300',
            E: '#fdd400',
            F: '#fa9101',
            G: '#d62015',
        },
    }

    return colors[region][label]
}

utils.consumptions = {
    canonical_unit: {
        Brussels: {
            electricity: {
                min: 2153,
                med: 5284,
                max: 10960,
            },
            electricity_heat: {
                min: 3153,
                med: 7884,
                max: 17560,
            },
            gas: {
                min: 595,
                med: 1459,
                max: 3027,
            },
            oil: {
                min: 457,
                med: 1121,
                max: 2325,
            },
            wood: {
                min: 1420,
                med: 2557,
                max: 4048,
            },
            pellets: {
                min: 1380,
                med: 2483,
                max: 3932,
            },
            coal: {
                min: 768,
                med: 1382,
                max: 2188,
            },
            district_heating: {
                min: 5500,
                med: 13500,
                max: 28000,
            },
        },
        Flanders: {
            electricity: {
                min: 8695,
                med: 15651,
                max: 24781,
            },
            electricity_heat: {
                min: 10295,
                med: 19251,
                max: 32381,
            },
            gas: {
                min: 1081,
                med: 1946,
                max: 3081,
            },
            oil: {
                min: 1205,
                med: 2169,
                max: 3434,
            },
            wood: {
                min: 1420,
                med: 2557,
                max: 4048,
            },
            pellets: {
                min: 1380,
                med: 2483,
                max: 3932,
            },
            coal: {
                min: 768,
                med: 1382,
                max: 2188,
            },
            district_heating: {
                min: 10000,
                med: 18000,
                max: 28500,
            },
        },
        Wallonia: {
            electricity: {
                min: 6029,
                med: 11423,
                max: 18404,
            },
            electricity_heat: {
                min: 7629,
                med: 15223,
                max: 27604,
            },
            gas: {
                min: 1027,
                med: 1946,
                max: 2900,
            },
            oil: {
                min: 1067,
                med: 2022,
                max: 3258,
            },
            wood: {
                min: 1304,
                med: 2471,
                max: 3981,
            },
            pellets: {
                min: 1267,
                med: 2400,
                max: 3866,
            },
            coal: {
                min: 705,
                med: 1336,
                max: 2152,
            },
            district_heating: {
                min: 9500,
                med: 18000,
                max: 29000,
            },
        },
    },
    kwh: {
        Brussels: {
            electricity: {
                min: 2153,
                med: 5284,
                max: 10960,
            },
            electricity_heat: {
                min: 1000,
                med: 2600,
                max: 6600,
            },
            gas: {
                min: 5500,
                med: 13500,
                max: 28000,
            },
            oil: {
                min: 4603,
                med: 11297,
                max: 23432,
            },
            wood: {
                min: 6250,
                med: 11250,
                max: 17812,
            },
            pellets: {
                min: 6250,
                med: 11250,
                max: 17812,
            },
            coal: {
                min: 6250,
                med: 11250,
                max: 17812,
            },
            district_heating: {
                min: 5500,
                med: 13500,
                max: 28000,
            },
        },
        Flanders: {
            electricity: {
                min: 8695,
                med: 15651,
                max: 24781,
            },
            electricity_heat: {
                min: 1600,
                med: 3600,
                max: 7600,
            },
            gas: {
                min: 10000,
                med: 18000,
                max: 28500,
            },
            oil: {
                min: 12146,
                med: 21862,
                max: 14615,
            },
            wood: {
                min: 6250,
                med: 11250,
                max: 17812,
            },
            pellets: {
                min: 6250,
                med: 11250,
                max: 17812,
            },
            coal: {
                min: 6250,
                med: 11250,
                max: 17812,
            },
            district_heating: {
                min: 10000,
                med: 18000,
                max: 28500,
            },
        },
        Wallonia: {
            electricity: {
                min: 6029,
                med: 11423,
                max: 18404,
            },
            electricity_heat: {
                min: 1600,
                med: 3800,
                max: 9200,
            },
            gas: {
                min: 9500,
                med: 18000,
                max: 29000,
            },
            oil: {
                min: 10757,
                med: 20383,
                max: 32839,
            },
            wood: {
                min: 5738,
                med: 10871,
                max: 17515,
            },
            pellets: {
                min: 5738,
                med: 10871,
                max: 17515,
            },
            coal: {
                min: 5738,
                med: 10871,
                max: 17515,
            },
            district_heating: {
                min: 9500,
                med: 18000,
                max: 29000,
            },
        },
    },
}

utils.epc_percent_mapping = (region, epcScore) => {
    const data = {
        Brussels: {
            epcs: [660, 580, 500, 420, 345, 275, 210, 150, 95, 45, 0],
            percents: [7, 5, 7, 13, 21, 19, 15, 9, 3, 1, 0],
        },
        Flanders: {
            epcs: [1000, 900, 800, 700, 600, 500, 400, 300, 200, 100, 0, -100],
            percents: [3, 2, 3, 5, 8, 13, 14, 18, 20, 13, 0, 0],
        },
        Wallonia: {
            epcs: [900, 800, 700, 600, 510, 425, 340, 255, 170, 86, 65, 0, -20],
            percents: [5, 3, 5, 8, 14, 17, 18, 15, 10, 4, 1, 0, 0],
        },
    }
    if (epcScore !== undefined) {
        const bestEpc = data[region].epcs[data[region].epcs.length - 1]
        if (epcScore < bestEpc) {
            return 0
        }
        let percent = 100
        for (let i = 0; i < data[region].epcs.length - 1; i++) {
            const currentEpcVal = data[region].epcs[i]
            if (epcScore <= currentEpcVal) {
                percent -=
                    data[region].percents[
                        utils.clamp(i + 1, 0, data[region].percents.length - 1)
                    ]
            }
        }
        return utils.clamp(percent, 0, 100)
    } else {
        return data[region]
    }
}

utils.epc_mapping = {
    Wallonia: {
        'A++': -20,
        'A+': 0,
        A: 65,
        B: 86,
        C: 170,
        D: 255,
        E: 340,
        F: 425,
        G: 510,
    },
    'BE-WAL': {
        'A++': -20,
        'A+': 0,
        A: 65,
        B: 86,
        C: 170,
        D: 255,
        E: 340,
        F: 425,
        G: 510,
    },
    Flanders: {
        'A+': -100,
        A: 0,
        B: 100,
        C: 200,
        D: 300,
        E: 400,
        F: 500,
    },
    'BE-VLG': {
        'A+': -100,
        A: 0,
        B: 100,
        C: 200,
        D: 300,
        E: 400,
        F: 500,
    },
    Brussels: {
        A: 0,
        B: 45,
        C: 95,
        D: 150,
        E: 210,
        F: 275,
        G: 345,
    },
    'BE-BRU': {
        A: 0,
        B: 45,
        C: 95,
        D: 150,
        E: 210,
        F: 275,
        G: 345,
    },
}

utils.epc_label = (region, epc_value) => {
    if (!region || isNaN(epc_value)) return '?'

    let label = '?'

    Object.keys(utils.epc_mapping[region]).every((key) => {
        label = key
        if (epc_value > utils.epc_mapping[region][key]) {
            return true
        } else return false
    })

    return label
}

utils.epc_color = (region, label) => {
    if (!label || label === '?') return '#798DA6'

    let colors = {
        Wallonia: {
            'A++': '#00bd00',
            'A+': '#00bd00',
            A: '#00bd00',
            B: '#9ade27',
            C: '#d8db1a',
            D: '#fdf300',
            E: '#fdd400',
            F: '#fa9101',
            G: '#d62015',
        },
        'BE-WAL': {
            'A++': '#00bd00',
            'A+': '#00bd00',
            A: '#00bd00',
            B: '#9ade27',
            C: '#d8db1a',
            D: '#fdf300',
            E: '#fdd400',
            F: '#fa9101',
            G: '#d62015',
        },
        Flanders: {
            'A+': '#00bd00',
            A: '#9ade27',
            B: '#d8db1a',
            C: '#fdf300',
            D: '#fdd400',
            E: '#fa9101',
            F: '#d62015',
        },
        'BE-VLG': {
            'A+': '#00bd00',
            A: '#9ade27',
            B: '#d8db1a',
            C: '#fdf300',
            D: '#fdd400',
            E: '#fa9101',
            F: '#d62015',
        },
        Brussels: {
            A: '#00bd00',
            B: '#9ade27',
            C: '#d8db1a',
            D: '#fdf300',
            E: '#fdd400',
            F: '#fa9101',
            G: '#d62015',
        },
        'BE-BRU': {
            A: '#00bd00',
            B: '#9ade27',
            C: '#d8db1a',
            D: '#fdf300',
            E: '#fdd400',
            F: '#fa9101',
            G: '#d62015',
        },
    }

    return colors[region][label]
}

utils.value_formatter = new Intl.NumberFormat('fr-BE', {
    style: 'currency',
    currency: 'EUR',
    maximumSignificantDigits: 3,
}).format

utils.int_value_formatter = (value, lang) => {
    const formater = new Intl.NumberFormat(lang, {
        style: 'currency',
        currency: 'EUR',
        maximumFractionDigits: 0,
    }).format
    return formater(value)
}

utils.exact_value_formatter = new Intl.NumberFormat('fr-BE', {
    style: 'currency',
    currency: 'EUR',
}).format

utils.quantile_formatter = (value) => {
    if (value > 0.5) {
        return `top ${Math.ceil((1 - value) * 20) * 5}%`
    } else {
        return `bottom ${Math.ceil(value * 20) * 5}%`
    }
}

utils.isEmptyStr = (str) => {
    return !str.replace(/\s/g, '').length
}

utils.isEmptyObject = (obj) => {
    return (
        obj &&
        Object.keys(obj).length === 0 &&
        Object.getPrototypeOf(obj) === Object.prototype
    )
}

utils.urlJoin = (baseUrl, paths) => {
    var fullPath = ''

    if (!(baseUrl.startsWith('/') || baseUrl.startsWith('http') || baseUrl === '')) {
        fullPath += '/'
    }

    if (baseUrl.endsWith('/')) {
        fullPath += baseUrl.slice(0, -1)
    } else {
        fullPath += baseUrl
    }

    if (!Array.isArray(paths)) {
        if (!paths.startsWith('/')) {
            fullPath += '/'
        }

        fullPath += paths

        try {
            var returnURI = new URI(fullPath)

            return returnURI.toString()
        } catch (e) {
            throw new Error('Invalid parameters for urlJoin function.')
        }
    } else {
        if (paths[0] === null) {
            var remainingPaths = [...paths]
            remainingPaths.shift()

            return utils.urlJoin(baseUrl, remainingPaths)
        } else {
            if (!paths[0].startsWith('/')) {
                fullPath += '/'
            }

            fullPath += paths[0]

            try {
                var returnURI = new URI(fullPath)

                if (paths.length === 1) {
                    return returnURI.toString()
                }
            } catch (e) {
                throw new Error('Invalid parameters for urlJoin function.')
            }

            var remainingPaths = [...paths]
            remainingPaths.shift()

            return utils.urlJoin(returnURI.toString(), remainingPaths)
        }
    }
}

utils.val_urls = (conf) => {
    return {
        request: utils.urlJoin(conf.VALUATION_API_URL, 'request'),
        request_ref: (valuation_request_ref) =>
            utils.urlJoin(conf.VALUATION_API_URL, ['request', valuation_request_ref]),
        request_ref_status: (valuation_request_ref) =>
            utils.urlJoin(conf.VALUATION_API_URL, [
                'request',
                valuation_request_ref,
                'status',
            ]),
        request_ref_owner: (valuation_request_ref) =>
            utils.urlJoin(conf.VALUATION_API_URL, [
                'request',
                valuation_request_ref,
                'owner',
            ]),
        request_ref_valuer: (valuation_request_ref) =>
            utils.urlJoin(conf.VALUATION_API_URL, [
                'request',
                valuation_request_ref,
                'valuer',
            ]),
        request_ref_type: (valuation_request_ref) =>
            utils.urlJoin(conf.VALUATION_API_URL, [
                'request',
                valuation_request_ref,
                'type',
            ]),
        request_ref_borrower: (valuation_request_ref) =>
            utils.urlJoin(conf.VALUATION_API_URL, [
                'request',
                valuation_request_ref,
                'borrower',
            ]),
    }
}

utils.parseJwt = (token) => {
    var base64Url = token.split('.')[1]
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    var jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
            })
            .join('')
    )

    return JSON.parse(jsonPayload)
}

utils.getCookie = (name) => {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) {
        return parts.pop().split(';').shift()
    }
    return undefined
}

utils.deleteCookie = (name) => {
    document.cookie = `${name}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT`
}

utils.formatDate = (now) => {
    return `${now.getFullYear()}-${('0' + now.getMonth() + 1).slice(-2)}-${(
        '0' + now.getDate()
    ).slice(-2)}-${('0' + now.getHours()).slice(-2)}-${('0' + now.getMinutes()).slice(
        -2
    )}`
}

utils.arrayToggle = (array, value) => {
    var index = array.indexOf(value)

    if (index === -1) {
        array.push(value)
    } else {
        array.splice(index, 1)
    }
}

//https://stackoverflow.com/a/37164538
utils.isObject = (item) => item && typeof item === 'object' && !Array.isArray(item)

utils.mergeDeep = (target, source) => {
    let output = Object.assign({}, target)
    if (isObject(target) && isObject(source)) {
        Object.keys(source).forEach((key) => {
            if (isObject(source[key])) {
                if (!(key in target)) Object.assign(output, { [key]: source[key] })
                else output[key] = mergeDeep(target[key], source[key])
            } else {
                Object.assign(output, { [key]: source[key] })
            }
        })
    }
    return output
}

utils.numberWithSpaces = (x) => {
    return x.toLocaleString('fr-BE')
}

utils.numberWithPoints = (x) => {
    return x.toLocaleString('de-DE')
}

utils.priceFormat = (x, thousands = true) => {
    // Non-breakable space is char 0xa0 (160 dec)
    return `€\xa0${utils.numberWithPoints(
        Math.round(x / (thousands ? 1000 : 10)) * (thousands ? 1000 : 10)
    )}`
}

utils.random_string = (length) => {
    const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let result = ''
    let values = new Uint32Array(length)
    window.crypto.getRandomValues(values)
    for (let i = 0; i < length; i++) {
        result += charset[values[i] % charset.length]
    }
    return result
}

// https://stackoverflow.com/a/3464346 but modified because it made some mistakes or didn't conform to what we're trying to achieve
utils.dateDiffInBusinessDays = (dDate1, dDate2) => {
    var iWeeks,
        iDateDiff,
        iAdjust = 0
    if (dDate2 < dDate1) return -1 // error code if dates transposed
    var iWeekday1 = dDate1.getDay() // day of week
    var iWeekday2 = dDate2.getDay()
    iWeekday1 = iWeekday1 == 0 ? 7 : iWeekday1 // change Sunday from 0 to 7
    iWeekday2 = iWeekday2 == 0 ? 7 : iWeekday2
    if (iWeekday1 > 5) iAdjust = 1
    iWeekday1 = iWeekday1 > 5 ? 5 : iWeekday1 // only count weekdays
    iWeekday2 = iWeekday2 > 5 ? 5 : iWeekday2

    // calculate differnece in weeks (1000mS * 60sec * 60min * 24hrs * 7 days = 604800000)
    iWeeks = Math.floor((dDate2.getTime() - dDate1.getTime()) / 604800000)

    if (iWeekday1 <= iWeekday2) {
        iDateDiff = iWeeks * 5 + (iWeekday2 - iWeekday1)
    } else {
        iDateDiff = (iWeeks + 1) * 5 - (iWeekday1 - iWeekday2)
    }

    iDateDiff -= iAdjust

    return iDateDiff
}

utils.dateDiffInDays = (a, b) => {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24

    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate())
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate())

    return Math.floor((utc2 - utc1) / _MS_PER_DAY)
}

utils.generateRequestId = (onlyNums = true) => {
    return Math.random()
        .toString(onlyNums ? 10 : 36)
        .slice(2)
}

// https://stackoverflow.com/a/60783784
utils.generateIntervalsOf = (interval, start, end, endIncluded = false) => {
    const result = []
    let current = start

    while (current < end) {
        result.push(current)
        current += interval
    }

    return result
}

utils.full_address = (address, features) => {
    if (!address) return ''
    let building_type = features ? features.f_building_type : null
    if (building_type === 'house' || building_type === 'apartment' || !building_type) {
        if (!address.streetname) return ''
        let box_appendix = address.boxnumber ? ` b ${address.boxnumber}` : ''
        if (address.postalcode !== null) {
            return `${address.streetname} ${address.streetnumber}${box_appendix}, ${address.postalcode} ${address.municipality}`
        }
    } else {
        let parcel_ids = features ? features.parcel_ids : null
        if (parcel_ids) {
            return parcel_ids[0]
        }
    }
    return ''
}

utils.via_address = (address, features) => {
    if (!address) return ''
    let building_type = features ? features.f_building_type : null
    if (
        building_type !== 'house' &&
        building_type !== 'apartment' &&
        building_type !== null
    ) {
        return utils.full_address(address)
    }
    return ''
}

utils.short_address = (address, features) => {
    let building_type = features ? features.f_building_type : null
    if (building_type === 'house' || building_type === 'apartment' || !building_type) {
        let box_appendix = address.boxnumber ? ` b ${address.boxnumber}` : ''
        if (address.postalcode !== null) {
            return `${address.streetname} ${address.streetnumber}${box_appendix}, ${address.postalcode}`
        }
    } else {
        let parcel_ids = features ? features.parcel_ids : null
        if (parcel_ids) {
            return parcel_ids[0]
        }
    }
    return ''
}

//https://dev.to/gladchinda/javascript-tip-whatis-a-better-typeof-3g0o
utils.whatis = (value) => {
    return Object.prototype.toString
        .call(value)
        .replace(/^\[object\s+([a-z]+)\]$/i, '$1')
        .toLowerCase()
}

utils.is_apartment = (f_building_type) =>
    ['apartment', 'new_apartment'].includes(f_building_type)
utils.is_house = (f_building_type) => ['house', 'new_house'].includes(f_building_type)
utils.is_building = (f_building_type) =>
    utils.is_house(f_building_type) || utils.is_apartment(f_building_type)
utils.is_new = (f_building_type) =>
    ['new_house', 'new_apartment'].includes(f_building_type)
utils.is_plot = (f_building_type) => ['construction_plot'].includes(f_building_type)

utils.forced_sale_value = (value) => {
    const x = value / 1000000
    let y
    if (x < 0.2) {
        y = 1 - x
    } else if (x < 0.4) {
        y = 0.9 - x / 2
    } else {
        y = 0.8 - x / 4
    }
    // Above lines are the best way we found to replace numpy.interp for more than just one segment
    // x vector is [100000, 200000, 400000, 800000]
    // y vector is [0.9, 0.8, 0.7, 0.6]
    // We divide by 1MIL before doing operations to avoid having too many zeroes lying around

    return Math.round((y * value) / 1000) * 1000
}

utils.rolesToList = (userRoles) => {
    const rolesList = []
    for (const [mod, roles] of Object.entries(userRoles)) {
        for (const role of roles) {
            rolesList.push(`${mod}:${role}`)
        }
    }
    rolesList.sort()
    return rolesList
}
utils.listToRoles = (rolesList) => {
    const userRoles = {}
    for (const userRole of rolesList) {
        const [mod, role] = userRole.split(':')
        if (mod in userRoles) {
            userRoles[mod].push(role)
        } else {
            userRoles[mod] = [role]
        }
    }
    return userRoles
}

utils.uuidv4 = () =>
    '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c) =>
        (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(
            16
        )
    )

export default utils
