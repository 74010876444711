var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"sidebar"}},[_c('div',{staticClass:"sidebar-back"},[_c('div',{staticClass:"container section p-3"},[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-12 pb-0"},[_c('auto-complete',{attrs:{"placeholder":_vm.house.address_text.full_address,"size":"is-normal"},on:{"selectedItem":function($event){return _vm.selectAddress($event)}}})],1),_c('div',{staticClass:"column is-12"},[_c('b-button',{staticClass:"is-primary is-outlined is-fullwidth",attrs:{"tag":"router-link","to":{
                            name: 'report',
                            params: { lang: _vm.$route.params.lang },
                            query: _vm.$route.query,
                        }}},[_vm._v(" "+_vm._s(_vm.$t('report'))+" ")])],1)])])]),_c('div',{key:'general',staticClass:"category-info location"},[_c('div',{staticClass:"info-content"},[_vm._m(0),_c('div',{staticClass:"info-name"},[_vm._v(_vm._s(_vm.house.address_text.full_address))])])]),_vm._l((_vm.sections.filter(
            (section) => section.items.length || _vm.$route.query.showmissing
        )),function(section){return _c('b-collapse',{key:section.name,staticClass:"card",attrs:{"animation":"slide","open":_vm.openSection == section.name},on:{"open":function($event){_vm.openSection = section.name}},scopedSlots:_vm._u([{key:"trigger",fn:function(props){return [_c('div',{staticClass:"card-header content level",attrs:{"role":"button"}},[_c('span',{staticClass:"level-left icon-text"},[(section.name !== 'warnings' && section.icon)?_c('img',{staticClass:"icon is-medium my-2 mx-3",attrs:{"src":`${_vm.publicPath}/${section.icon}.svg`}}):_vm._e(),(section.name === 'warnings')?_c('span',{staticClass:"icon is-medium my-2 mx-3 has-text-warning"},[_c('i',{staticClass:"mdi mdi-alert mdi-24px"})]):_vm._e(),_c('span',{staticClass:"noselect has-text-weight-bold is-size-6"},[_vm._v(" "+_vm._s(_vm.$t('section.' + section.name))+" ")])]),_c('span',{staticClass:"level-right card-header-icon"},[_c('span',{staticClass:"mr-4 tag is-rounded"},[_vm._v(" "+_vm._s(section.items.length)+" ")]),_c('i',{staticClass:"arrow",class:props.open ? 'up' : 'down'})])])]}}],null,true)},[_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"content"},_vm._l((section.items),function(item){return _c('section-item',{key:item.name,attrs:{"item":item,"orientation":'horizontal'}})}),1)])])})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/icons/ic-location-blue.svg"),"alt":"location icon"}})])
}]

export { render, staticRenderFns }